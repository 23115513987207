<template>
  <div class="doneOrderList">
    <div class="doneOrderList_searchBox">
      <searchV1
          class="doneOrderList_searchBox_item"
          v-model="keyword"
          @search="search"
          @clear="search"
          placeholder="请输入配送的站点名称或商品名称"
      ></searchV1>
    </div>
    <div class="date-ranger-container">
      <date-ranger-v1 @chooseDate="chooseDate"></date-ranger-v1>
    </div>
    <div class="doneOrderList_tips">
      <div class="doneOrderList_tip_top">
        <div class="doneOrderList_tip_top_left">
          <div class="tip_top_left_first"><img style="width:18px;vertical-align:middle;"
                                               :src="require('@STATIC/image/icons/rmb.png')"/>
            我的佣金（元）
          </div>
          <div class="tip_top_left_second"><span style="font-size: 10px">￥</span>{{
              statistics.total_delivery_money
            }}
          </div>
        </div>
        <div class="doneOrderList_tip_top_right">
          <div @click="goToWithMoney" class="tip_top_button">去提现</div>
        </div>
      </div>
      <div class="doneOrderList_tip_down">
        <div class="doneOrderList_tip_down_left">
          <div style="line-height: 24px; height: 24px;">配送单数：{{ statistics.total_sum }}</div>
        </div>
      </div>
    </div>
    <div class="doneOrderList_body">
      <scrollview @onRefresh="onRefresh" @onReload="onReload" :height="scrollHeight">
        <div class="order-list" v-if="list.length > 0">
          <div
              class="card"
              v-for="(item, index) in list"
              :key="index"
          >
            <div @click="contact(item.mobile)">
              <span> 配送编号：{{ item.order_sn }} </span>
              <span>佣金 +{{ item.money }}元</span>
            </div>
            <div>
              <span @click="contact(item.mobile)">自提站点：</span>
              <span>{{ item.site_name }}</span>
            </div>
            <div @click="contact(item.mobile)">
              <span>负责人：</span>
              <span>{{ item.site_leader }}</span>
            </div>
            <div>
              <span>送达时间：</span>
              <span>{{ item.site_point_receive_time }}</span>
            </div>
            <div>
              <span>配送商品：</span>
              <span>
                                <a class="btn" @click="changeShowOrHide(index)">
                                    {{ item.hide_goods == 1 ? '展开' : '收起' }}
                                </a>
                            </span>
            </div>
            <div class="goods-list" v-show="item.hide_goods == 0">
              <div class="goods-info" v-for="(goods,goodsIndex) in item.goods">
                <div class="goods-img">
                  <img :src="goods.path"/>
                </div>
                <div class="info">
                  <div class="goods-title">
                    {{ goods.title }}
                  </div>
                  <div class="goods-number">
                    <span>{{ goods.spec_text ? goods.spec_text : '默认规格' }}</span>× {{ goods.total }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </scrollview>
    </div>
  </div>
</template>

<script>
import searchV1 from '../../template/community/search/searchV1'
import CellV3 from '../../template/community/cell/cellV3'
import DateRangerV1 from '../../template/community/date/dateRangerV1'

export default {
  name: 'done-order-list',
  components: {
    DateRangerV1,
    CellV3,
    searchV1,
  },
  data() {
    return {
      keyword: '',
      date: ['', ''],
      statistics: {},
      params: {
        page: 1,
        page_size: 10,
      },
      list: [],
      scrollHeight: 0
    }
  },
  mounted() {
    this.getDoneOrderList()
    // this.scrollHeight = window.innerHeight - document.querySelector('.doneOrderList_searchBox').clientHeight - document.querySelector('.date-ranger-container').clientHeight - document.querySelector('.doneOrderList_tip').clientHeight
    this.scrollHeight = window.innerHeight - document.querySelector('.doneOrderList_searchBox').clientHeight - document.querySelector('.date-ranger-container').clientHeight - document.querySelector('.doneOrderList_tips').clientHeight
    document.title = '配送记录'
  },
  methods: {
    onRefresh(done) {
      this.list = []
      this.params.page = 1
      this.getDoneOrderList().finally(() => {
        done()
      })
    },
    /**
     * step 当前加载结束
     * over 没有更多数据了
     */
    onReload(step, over) {
      console.log('上拉加载')
      this.params.page += 1
      this.getDoneOrderList().finally(() => {
        if (this.list.length < this.params.page * this.params.page_size) {
          over()
        } else {
          step()
        }
      })
    },
    gotDetail: function (doId) {
      this.$router.push({path: 'order-info', query: {doId: doId}})
    },
    async getDoneOrderList() {
      try {
        let query = await this.$api.community.courier.getDoneOrderList({
          ...this.params,
          start_time: this.date[0],
          end_time: this.date[1],
          keyword: this.keyword,
        })
        this.statistics = query.data.tj
        if (query.data.list.length == 0) {
          return
        }
        this.list = [...this.list, ...query.data.list]
      } catch (e) {
        console.log('err', e)
      }
    },
    search() {
      this.params.page = 1
      this.list = []
      this.getDoneOrderList()
    },
    chooseDate(date) {
      this.date = date
      this.list = []
      this.params.page = 1
      this.getDoneOrderList()
    },
    changeShowOrHide(index) {
      let is_show = this.list[index].hide_goods == 0
      if (is_show) {
        this.list[index].hide_goods = 1
      } else {
        this.list[index].hide_goods = 0
      }
    },
    goToWithMoney() {
      if (this.$platform.wxsdk.isWechat()) {
        this.$platform.wxsdk.wxRoute({
          type: 'navigateTo',
          url: '/web/courier/money-center',
        })
      }
    },
    contact(mobile) {
      self.$platform.wxsdk.wxRoute({
        type: 'navigateTo',
        url:
            '/web/makePhoneCall/common?mobile=' + mobile,
      })
    }
  },
}
</script>

<style scoped lang="scss">
@include b(doneOrderList) {
  width: 100vw;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  @include e(searchBox) {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    @include e(item) {
      width: 345px;
      height: 32px;
    }
  }
  @include e(tip) {
    flex-shrink: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 12px 15px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #c10000;
    & > div:last-of-type {
      margin-left: 20px;
    }
  }
  @include e(body) {
    flex-grow: 1;
  }
}

.date-ranger-container {
  flex-shrink: 0;
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  padding: 0 15px;
}

.card {
  width: 345px;
  background: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 10px;
  padding-bottom: 1px;
  margin-bottom: 10px;

  & > div {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-bottom: 8px;

    &:first-of-type {
      padding: 10px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      background: linear-gradient(129deg, #fbf2e2 0%, #f1e2c9 100%);
      border-radius: 10px 10px 0px 0px;
      font-weight: 400;
    }
  }
}

.btn {
  color: #108ee9;
}

.goods-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .goods-info {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    background: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: #999 dashed 1px;
    padding: 3px 10px;

    .goods-img {
      width: 40px;
      height: 40px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      overflow: hidden;
      margin-right: 10px;

      img {
        width: 40px;
        height: 40px;
      }
    }

    .info {
      flex: 1;
      display: flex;
      flex-direction: column;

      .goods-title {
        font-size: 14px;
        margin-top: 5px;
      }

      .goods-specs {
        font-size: 14px;
        color: #ccc;
        margin-top: 6px;
      }

      .goods-number {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        color: red;
        font-weight: bold;

        span {
          font-size: 14px;
          margin-right: 20px;
          font-weight: 300;
          color: #333;
        }
      }
    }

  }
}

.doneOrderList_tips {
  border: 0px solid #c10000;
  width: 345px;
  height: 128px;
  background: linear-gradient(180deg, #FF934E 0%, #F33D40 100%);
  border-radius: 8px;
  margin: auto;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  color: #ffffff;

  .doneOrderList_tip_top {
    width: 345px;
    height: 80px;
    display: flex;

    .doneOrderList_tip_top_left {
      width: 50%;

      .tip_top_left_first {
        padding-left: 15px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        height: 34px;
        line-height: 34px;

      }

      .tip_top_left_second {
        padding-left: 25px;
        height: 52px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 52px;
      }
    }

    .doneOrderList_tip_top_right {
      width: 50%;

      .tip_top_button {
        width: 79px;
        height: 30px;
        background: #FFFFFF;
        border-radius: 15px;
        margin-top: 35px;
        margin-left: 70px;

        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #F46A17;
        line-height: 30px;
        text-align: center;
      }
    }

  }

  .doneOrderList_tip_down {

    width: 345px;
    height: 48px;
    background: linear-gradient(360deg, #F33D40 0%, #FF934E 100%);
    border-radius: 0px 0px 8px 8px;
    display: flex;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    align-items: center;

    .doneOrderList_tip_down_left {
      padding-left: 15px;
      width: 50%;
      height: 48px;
      display: flex;
      align-items: center;
    }

    .doneOrderList_tip_down_right {
      padding-left: 15px;
      width: 50%;
      height: 48px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
